// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-event-js": () => import("/opt/build/repo/src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advertise-js": () => import("/opt/build/repo/src/pages/advertise.js" /* webpackChunkName: "component---src-pages-advertise-js" */),
  "component---src-pages-calendar-js": () => import("/opt/build/repo/src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-confirmation-js": () => import("/opt/build/repo/src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-submit-an-event-js": () => import("/opt/build/repo/src/pages/submit-an-event.js" /* webpackChunkName: "component---src-pages-submit-an-event-js" */)
}

